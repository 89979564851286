import React, { PureComponent } from 'react';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import styled from "styled-components";

function PatientStatisticChart() {
    const data = [
        {
          name: 'Page A',
          uv: 4000,
          pv: 2400,
          amt: 2400,
        },
        {
          name: 'Page B',
          uv: 3000,
          pv: 1398,
          amt: 2210,
        },
        {
          name: 'Page C',
          uv: 2000,
          pv: 9800,
          amt: 2290,
        },
        {
          name: 'Page D',
          uv: 2780,
          pv: 3908,
          amt: 2000,
        },
        {
          name: 'Page E',
          uv: 1890,
          pv: 4800,
          amt: 2181,
        },
        {
          name: 'Page F',
          uv: 2390,
          pv: 3800,
          amt: 2500,
        },
        {
          name: 'Page G',
          uv: 3490,
          pv: 4300,
          amt: 2100,
        },
      ];
  return (
    <Wrapper>
    <div className='container-fluid mt-4 ms-4 ' id='main'>
    <div className='row'>
      <div className='col-12 d-flex justify-content-center'>
    <BarChart
    width={500}
    height={300}
    data={data}
    margin={{
      top: 5,
      right: 60,
      left: 60,
      bottom: 5,
    }}
  >
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="name" />
    <YAxis />
    <Tooltip />
    <Legend />
    <Bar dataKey="pv" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
    <Bar dataKey="uv" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} />
  </BarChart>
  </div>
  </div>
  </div>
  </Wrapper>
  )
}

export default PatientStatisticChart;

const Wrapper = styled.div`
#main{
    
    background-color: white;
    width: 80%;
    border-radius: 5px;
   
}
@media screen and (max-width: 768px) {
    padding: 20px;
    font-size: small;
     
    }

`;